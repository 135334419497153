@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

/* App.css */
.custom-button {
    background-color: #007ad9;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.custom-button:hover {
    background-color: #005c9f;
}

/* App.css */
/* App.css */
.info-color {
    background-color: rgba(187, 49, 246, 0.3);
}

